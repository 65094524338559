// composables/useLocalStorage.ts
export const useLocalStorage = () => {
  const setItem = (key: string, value: any): void => {
    if (import.meta.client) {
      localStorage.setItem(key, JSON.stringify(value))
    }
  }

  const getItem = (key: string): any => {
    if (import.meta.client) {
      const item = localStorage.getItem(key)
      return item ? JSON.parse(item) : null
    }
    return null
  }

  const removeItem = (key: string): void => {
    if (import.meta.client) {
      localStorage.removeItem(key)
    }
  }

  return {
    setItem,
    getItem,
    removeItem
  }
}